<template>
  <div >
    <a-row v-for="(scope, key) in dataTableList" :key="key">
        <a-col :span="24" class="list-header" style="line-height:64px;background: #F8FAFB;border: 1px solid #EBF0F2;padding-left: 1%;">
            <span>下单时间 {{scope.create_at}}</span>
            <span  @click="onCopyOrder(scope.order.order_no)" style="padding-left:1%;cursor:pointer;">订单编号 {{scope.order.order_no}}  <icon-font type="iconcopy"></icon-font></span>
            
        </a-col>
        <a-col :span="24" style="height:130px;border: 1px solid #EBF0F2;display:flex;align-items:center;padding-left: 1%;">
            <a-col :span="4">
                <img src="@/assets/images/web/music/vip_package.svg">
            </a-col>
            <a-col :span="4">
                <span>{{scope.goods_name}}</span>
            </a-col>
            <a-col :span="4">
                <span>授权次数 {{scope.authed_num}}</span>
                <span> / {{scope.auth_num}}</span>
            </a-col>
            <a-col :span="4">
                <span>下载次数 {{scope.downloaded_num}}</span>
                <span> / {{scope.download_num}}</span>
            </a-col>
            <a-col :span="16" style="text-align:left;">
                <a-col :span="24">
                    <span>授权用途: {{scope.use_mode}}</span>
                </a-col>
                <a-col :span="24">
                    <span>授权地域: {{scope.auth_area | toArea}}</span>
                </a-col>
                <a-col :span="24">
                    <span>授权期限: {{scope.auth_time | toAuthPeriod}}</span>
                </a-col>
                <a-col :span="24">
                    <span>到期时间: {{scope.expire_date}}</span>
                </a-col>
            </a-col>
        </a-col>
    </a-row>
    <a-row v-if="dataTableList.length == 0" style="text-align:center;padding-top:24px;">
        <img src="@/assets/images/empty.png" style="width:76px;"/>
        <br/><span style="color:rgba(0, 0, 0, 0.25);">暂无数据</span>
    </a-row>
  </div>
</template>

<script>
  import { copyStr } from '@/utils/function'
  export default {
    data() {
      return {
        dataTableList:[],
      }
    },

    props: {
    },
    components: {
    },

    created: function () {
        this.getData();
    },

    methods: {
        onCopyOrder(str) {
            try {
                copyStr(str);
                this.$message.success(this.$t('result.copySuccess'));
            } catch (error) {
                console.error(error);
            }
        },
        getData() {
            let params = {};
            this.$axios.Package_listInUser(params).then(res => {
                const data = res.data;
                if (data && data.code == 0) {
                    let list = data.data ? data.data.list : [];
                    this.dataTableList = list;
                }
            })
        },
    }
  }
</script>

<style lang="scss" scoped>
</style>
